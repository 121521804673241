import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Checkbox} from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { PostService } from './billService';

const PostBill = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({
    helmet: 2,
    expected: 1
  });
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới',
    partnerList: [],
    motobikeList: [],
    resourceList:[
      {id: 'doi_tac', name: 'Đối tác'},
      {id: 'khach_ngoai', name: 'Khách vãng lai'},
      {id: 'khach_online', name: 'Khách online'},
      {id: 'khach_kho', name: 'Khách kho'}
    ]
  });

  useEffect(() => {
    init();
  }, []);

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await PostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const init = () => {
    Popup.spin.show();
    PostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          id: props.id,
          name: item.name,
          price: item.price,
          phone: item.phone,
          extId: item.extId,
          description: item.description,
          descriptionUpdateBill: item.descriptionUpdateBill,
          payment: item.payment,
          dateCreated: item.dateCreated,
          dateReturn: item.dateReturn,
          resource: item.resource,
          isDebt: item.isDebt,
          partnerId: item.partnerId,
          commission: item.commission,
          helmet: item.helmet ?? 2,
          expected: item.expected ?? 1,
          archived: item.archived
        });
        setCompState({
          ...compState,
          title: props.id ? item.extId : 'Tạo mới',
          partnerList: res.partnerList ?? [],
          motobikeList: res.motobikeList ?? []
        });
      }
    })
  }

  const onChangePartner = (value) => {
    setItemState({
      ...itemState,
      partnerId: value
    })
  }

  const onChangeMotobike = (value) => {
    setItemState({
      ...itemState,
      extId: value
    })
  }

  const onChangeMotobikes = (value) => {
    setItemState({
      ...itemState,
      extIds: value
    })
  }

  const onChangeResource = (value) => {
    setItemState({
      ...itemState,
      resource: value
    })
  }

  if(!compState)return <></>
  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col xs={24} sm={12}>
            <label>Khách hàng</label>
            <Input
							value={itemState.name}
							onChange={(event) => {
								setItemState({ ...itemState, name: event.target.value });
							}}
						/>
            <Msg target="name" msgs={msgs} className="errorMsg" />
          </Col>
          <Col>
            <label>Số điện thoại</label>
            <Input
              value={itemState.phone}
              onChange={(event) => 
                setItemState({ ...itemState, phone: event.target.value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Đơn giá</label>
            <InputNumber
              value={itemState.price}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, price: values.floatValue })}
            />
            <Msg target="price" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Thanh toán</label>
            <InputNumber
              value={itemState.payment}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, payment: values.floatValue })}
            />
          </Col>
          <Col xs={8} sm={8}>
            <label>Hoa hồng</label>
            <InputNumber
              value={itemState.commission}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, commission: values.floatValue })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Số ngày thuê</label>
            <InputNumber
              value={itemState.expected}
              placeholder='Số ngày thuê'
              onChange={(values) => setItemState({ ...itemState, expected: values.floatValue })}
            />
          </Col>
          <Col xs={8} sm={8}>
            <label>Số lượng nón</label>
            <InputNumber
              value={itemState.helmet}
              placeholder='Số lượng nón'
              onChange={(values) => setItemState({ ...itemState, helmet: values.floatValue })}
            />
          </Col>
        </Row>
        {!props.id &&
          <Row>
            <Col xs={24} sm={24}>
              <label>Xe thuê</label>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={itemState.extId}
                showSearch
                placeholder="Tìm xe"
                optionFilterProp="children"
                onChange={onChangeMotobikes}
                filterOption={(input, option) =>
                  (option?.children).toLowerCase().includes(input.toLowerCase())
                }
              >
                {compState.motobikeList.map(item => (
                  <Select.Option key={item.extId} value={item.extId}>
                    {item.extId}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        }
        <Row>
          {props.id && 
            <Col xs={8} sm={8}>
              <label>Xe thuê</label>
              <Select
                style={{ width: '100%' }}
                value={itemState.extId}
                showSearch
                placeholder="Tìm xe"
                optionFilterProp="children"
                onChange={onChangeMotobike}
                filterOption={(input, option) =>
                  (option?.children).toLowerCase().includes(input.toLowerCase())
                }
              >
                {compState.motobikeList.map(item => (
                  <Select.Option key={item.extId} value={item.extId}>
                    {item.extId}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          }
          <Col xs={8} sm={8}>
            <label>Nguồn khách</label>
            <Select
              value={itemState.resource}
              showSearch
              placeholder="Nguồn khách"
              optionFilterProp="children"
              onChange={onChangeResource}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {compState.resourceList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Msg target="resource" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Đối tác</label>
            <Select
              value={itemState.partnerId}
              allowClear
              showSearch
              placeholder="Tìm đối tác"
              optionFilterProp="children"
              onChange={onChangePartner}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {compState.partnerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Ngày thuê</label>
            <DatePicker
              inputReadOnly
              showTime
              value={moment(itemState.dateCreated)}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, dateCreated: value ? value.format() : null })}
            />
            <Msg target="dateCreated" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Ngày trả</label>
            <DatePicker
              inputReadOnly
              showTime
              value={itemState.dateReturn ? moment(itemState.dateReturn): ''}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, dateReturn: value ? value.format() : null })}
            />
            <Msg target="dateReturn" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={12} sm={4}>
            <label>Lưu ý</label>
            <Checkbox 
              checked={itemState.isDebt}
              onChange={(e) => {
              setItemState({...itemState, isDebt: e.target.checked})}}>Nợ HĐ</Checkbox>
          </Col>
          <Col xs={12} sm={4}>
            <label>Lưu trữ</label>
            <Checkbox 
              checked={itemState.archived}
              onChange={(e) => {
              setItemState({...itemState, archived: e.target.checked})}}>Lưu trữ HĐ</Checkbox>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
          </Col>
          <Col>
            <label>Lý do cập nhật</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.descriptionUpdateBill}
              placeholder="Lý do cập nhật"
              onChange={({ target: { value } }) => setItemState({ ...itemState, descriptionUpdateBill: value })}
            />
            <Msg target="descriptionUpdateBill" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostBill;