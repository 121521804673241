import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Checkbox} from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { PostService } from './orderService';

const PostOrder = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({
  });
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới',
    partnerList: [],
    statusList:[],
    kindList:[]
  });

  useEffect(() => {
    init();
  }, []);

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await PostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const init = () => {
    Popup.spin.show();
    PostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          id: props.id,
          name: item.name,
          price: item.price,
          phone: item.phone,
          amount: item.amount,
          statusId: item.statusId,
          description: item.description,
          createdDate: item.createdDate,
          rentalDate: item.rentalDate,
          returnDate: item.returnDate,
          isPaid: item.isPaid,
          payment: item.payment,
          partnerId: item.partnerId,
          kind: item.kind
        });
        setCompState({
          ...compState,
          title: props.id ? item.extId : 'Tạo mới',
          partnerList: res.partnerList ?? [],
          statusList: res.statusList ?? [],
          kindList: res.kindList ?? []
        });
      }
    })
  }

  const onChangePartner = (value) => {
    setItemState({
      ...itemState,
      partnerId: value
    })
  }

  const onChangeStatus = (value) => {
    setItemState({
      ...itemState,
      statusId: value
    })
  }

  const onChangeKind = (value) => {
    setItemState({
      ...itemState,
      kind: value
    })
  }

  const onExportPdf = async () => {
    Popup.spin.show();
    var res = await PostService.get({
      ...itemState
    });
    console.log('res', res);
    Popup.spin.hide();
  }
  
  if(!compState)return <></>
  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col xs={8} sm={8}>
            <label>Khách hàng</label>
            <Input
							value={itemState.name}
							onChange={(event) => {
								setItemState({ ...itemState, name: event.target.value });
							}}
						/>
            <Msg target="name" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Số điện thoại</label>
            <Input
              value={itemState.phone}
              onChange={(event) => 
                setItemState({ ...itemState, phone: event.target.value })}
            />
            <Msg target="phone" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Đối tác</label>
            <Select
              value={itemState.partnerId}
              allowClear
              showSearch
              placeholder="Tìm đối tác"
              optionFilterProp="children"
              onChange={onChangePartner}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {compState.partnerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Đơn giá/Thanh toán</label>
            <InputNumber
              value={itemState.price}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, price: values.floatValue })}
            />
            <Msg target="price" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Số lượng xe</label>
            <InputNumber
              value={itemState.amount}
              placeholder='Số lượng xe'
              onChange={(values) => setItemState({ ...itemState, amount: values.floatValue })}
            />
            <Msg target="amount" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Loại xe</label>
            <Select
              value={itemState.kind}
              placeholder="Loại xe"
              onChange={onChangeKind}
            >
              {compState.kindList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Msg target="status" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Ngày thuê</label>
            <DatePicker
              inputReadOnly
              showTime
              value={moment(itemState.rentalDate)}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, rentalDate: value ? value.format() : null })}
            />
            <Msg target="rentalDate" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Ngày trả dự kiến</label>
            <DatePicker
              inputReadOnly
              showTime
              value={itemState.returnDate ? moment(itemState.returnDate): ''}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, returnDate: value ? value.format() : null })}
            />
          </Col>
          <Col xs={8} sm={8}>
            <label>Trạng thái</label>
            <Select
              value={itemState.statusId}
              placeholder="Trạng thái"
              onChange={onChangeStatus}
            >
              {compState.statusList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Msg target="status" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Đã TT/Cọc</label>
            <InputNumber
              value={itemState.payment}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, payment: values.floatValue, isPaid: values.floatValue > 0})}
            />
            <Msg target="payment" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Thanh toán</label>
            <Checkbox 
              checked={itemState.isPaid}
              onChange={(e) => {
              setItemState({...itemState, isPaid: e.target.checked})}}>Thanh toán/Cọc</Checkbox>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
            <Msg target="description" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col>
              <Button onClick={onExportPdf}>Xuất Pdf</Button>
            </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
           <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostOrder;