import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Switch} from "antd";
import moment from "moment";

import { Format } from "../../common/format";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { PostMotorBikeService } from '../motorBike/motorBikeService';

const PostMotor = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({});
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới',
    kindList:[
      {id: 1, name: 'Ga'},
      {id: 2, name: 'Số'},
    ]
  });

  useEffect(() => {
    init();
  }, []);

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await PostMotorBikeService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const onChangeKind = (value) => {
    setItemState({
      ...itemState,
      kind: value
    })
  }

  const init = () => {
    Popup.spin.show();
    PostMotorBikeService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          id: props.id,
          extId: item.extId,
          type: item.type,
          assetCost: item.assetCost,
          kind: item.kind,
          createdDate: item.createdDate,
          motorOil: item.motorOil,
          motorOilDate: item.motorOilDate,
          odometer: item.odometer,
          odometerDate: item.odometerDate,
          gpsId: item.gpsId,
        });
        setCompState({
          ...compState,
          title: props.id ? item.extId : 'Tạo mới',
        });
      }
    })
  }

  if(!compState)return <></>
  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col xs={12} sm={5}>
            <label>Hiệu</label>
            <Input
							value={itemState.type}
              onChange={(event) => {
								setItemState({ ...itemState, type: event.target.value });
							}}
						/>
            <Msg target="type" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={12} sm={5}>
            <label>Loại xe</label>
            <Select
              value={itemState.kind}
              placeholder="Loại xe"
              onChange={onChangeKind}
            >
            {compState.kindList.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
            </Select>
            <Msg target="kind" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={12} sm={5}>
            <label>Giá trị xe</label>
            <InputNumber
							value={itemState.assetCost}
              onChange={(values) => setItemState({ ...itemState, assetCost: values.floatValue })}
						/>
            <Msg target="assetCost" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={12} sm={9}>
            <label>Ngày mua</label>
            <DatePicker
              inputReadOnly
              value={itemState.createdDate ? moment(itemState.createdDate): ''}
              format={Locale.getFormat().dateFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, createdDate: value ? value.format() : null })}
            />
            <Msg target="createdDate" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>Thay nhớt tại số km</label>
            <InputNumber
							value={itemState.motorOil}
              onChange={(values) => setItemState({ ...itemState, motorOil: values.floatValue })}
						/>
            <Msg target="motorOil" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={24} sm={12}>
            <label>Thay nhớt ngày</label>
            <DatePicker
              inputReadOnly
              value={itemState.motorOilDate ? moment(itemState.motorOilDate): ''}
              format={Locale.getFormat().dateFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, motorOilDate: value ? value.format() : null })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>Tổng số ODO</label>
            <InputNumber
							value={itemState.odometer}
              onChange={(values) => setItemState({ ...itemState, odometer: values.floatValue })}
						/>
            <Msg target="odometer" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={24} sm={12}>
            <label>Ngày cập nhật gần nhất</label>
            <DatePicker
              inputReadOnly
              value={itemState.odometerDate ? moment(itemState.odometerDate): ''}
              format={Locale.getFormat().dateFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, odometerDate: value ? value.format() : null })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>GpsId</label>
            <InputNumber
							value={itemState.gpsId}
              onChange={(values) => setItemState({ ...itemState, gpsId: values.floatValue })}
						/>
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostMotor;