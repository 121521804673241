import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button, Pagination, Checkbox, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputComp } from "../../common/component";

import { OrderManageService } from './orderService';
import { CurrentUserService } from "../user/currentUserService";

import PostOrder from "./postOrder";

const { RangePicker } = DatePicker;
const today = moment();

const ManageOrder = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList: [],
    defaultToday:today
  });

  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    isPaid: false,
    fromDate: today.format("YYYY-MM-DD"),
		toDate: null,
    partnerId: null,
    statusId: null,
    archived: false
  });

  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });

  const partnerListRef = useRef([]);
  const statusListRef = useRef([]);

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

    init();

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const init = () => {
    Popup.spin.show();
    OrderManageService.init().then(res => {
      Popup.spin.hide()
      if(res.success){
        partnerListRef.current = res.partnerList
        statusListRef.current = res.statusList
      }
    })
  }

  const getList = () => {
    Popup.spin.show();

    let filterParams = {
      ...filterState,
    };

    OrderManageService.getList({ ...filterParams }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangeStatus = (value) => {
    setFilterState({
      ...filterState,
      statusId: value
    });
  }

  const onChangeSearchOrder = (event) => {
    setFilterState({
      ...filterState,
      searchKey: event.target.value 
    })
  }

  const onChangePartner = (value) => {
    setFilterState({
      ...filterState,
      partnerId: value
    });
  }

  const onChangeRentalRangepicker = (dates) => {
    if(dates == null) return; 
    setFilterState({
      ...filterState,
      fromDate: dates[0] != null ? dates[0].format("YYYY-MM-DD") : null,
      toDate: dates[1] != null ? dates[1].format("YYYY-MM-DD") : null,
    });
  }

  const onChangeReturnDatepicker = (date) => {
    setFilterState({
      ...filterState,
      returnDate: date != null ? date.format("YYYY-MM-DD") : null,
    });
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const onAddItem = () => {
    setItemState({
      isItemDrawer: true,
    });
  }

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Lịch đặt xe</h1>
      <Row align="middle">
        {statusListRef.current &&
          <Col>
            <Select
              style={{ width: "160px" }}
              defaultValue={filterState.statusId}
              value={filterState.statusId}
              onChange={onChangeStatus}
            >
              {statusListRef.current.map(item => (
                <Select.Option key={item.statusId} value={item.statusId}>
                  {item.name}
                </Select.Option>
            ))}
            </Select>
          </Col>
        }
        {partnerListRef.current &&
          <Col>
            <Select
              value={compState.partnerId}
              showSearch
              allowClear
              style={{ width: "160px" }}
              placeholder="Tìm đối tác"
              optionFilterProp="children"
              onChange={onChangePartner}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
            {partnerListRef.current.map(item => (
              <Select.Option key={item.partnerId} value={item.partnerId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          </Col>
        }
        <Col>
          <InputComp placeholder="Tìm tên hoặc SĐT" onChange={onChangeSearchOrder} value={filterState.searchKey}/>
        </Col>
        <Col>
          <label>Ngày thuê: </label>
          <RangePicker
            defaultValue={[compState.defaultToday]}
            allowEmpty={[false,true]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRentalRangepicker}
            allowClear={true}
            />
        </Col>
        <Col>
          <label>Ngày trả: </label>
          <DatePicker
            format={Locale.getFormat().dateFormat}
            onChange={onChangeReturnDatepicker}
            allowClear={true}
            />
        </Col>
      </Row>
      <Row align="middle" justify="end">
        <Col>Số lượng đơn: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col style={{ marginLeft: 'auto' }}>
          {currentUserState.currentUser && currentUserState.currentUser.canEdit &&
            <Button type="primary" onClick={onAddItem}>
              <i className="fa fa-plus" />
              <span>Đặt xe</span>
            </Button>
          }
        </Col>
        <Col>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Thông tin</li>
            <li style={{minWidth:'80px'}}>Số lượng</li>
            <li>Ghi chú</li>
            <li>Ngày tạo</li>
            <li>Giá thuê</li>
            <li>Trạng thái</li>
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">
                {item.statusId == 1 && <i className="icon fa fa-pause" style={{color:'red', paddingLeft:'5px'}}></i>} 
                {item.statusId == 2 && <i className="icon fa fa-check" style={{color:'blue', paddingLeft:'5px'}}></i>}
                {item.statusId == 3 && <i className="icon fa fa-ban" style={{color:'red', paddingLeft:'5px'}}></i>}
                {item.statusId == 4 && <i className="icon fa fa-motorcycle" style={{color:'green', paddingLeft:'5px'}}></i>}
                {item.statusId == 5 && <i className="icon fas fa-check-circle" style={{color:'green', paddingLeft:'5px'}}></i>}
              </li>
              <li>
                <div>
                  &nbsp;{item.name}
                </div>
                {item.phone && item.phone !== '0' ? 
                  <em><strong>{item.phone}</strong></em> : ''
                }
                <div><em>Ngày thuê</em> <strong>{Format.date(new Date(item.rentalDate))}</strong></div>
                <div><em>Giờ thuê</em> <strong>{Format.time(new Date(item.rentalDate))}</strong></div>
                <div>Thuê {Format.number(item.amount)} ngày</div>
                {item.payment > 0 && <div><em>TT/Cọc</em> <strong>{Format.number(item.payment)}</strong></div>}
                <br/>
                {item.returnDate ? <>
                  <div>{Format.date(new Date(item.returnDate))}</div>
                  <div>{Format.time(new Date(item.returnDate))}</div>
                  </> : <div>Chưa xác định</div>}
              </li>
              <li>
                <strong>{item.amount} {item.kind}</strong>
              </li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}></li>
              <li>
                <div>{Format.date(new Date(item.createdDate))}</div>
                <div>{Format.time(new Date(item.createdDate))}</div>
              </li>
              <li>
                <div><strong>{Format.number(item.price)}</strong></div>
                <div><em>{item.partner}</em></div>
              </li>
              <li>
                <strong>{item.status}</strong>
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li style={{float:'right'}}>
                <Button type="primary" onClick={() => onEditItem(item)}>
                  Cập nhật
                </Button>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
            />
          }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostOrder id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageOrder;