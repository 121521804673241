import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select ,Dropdown, Menu, Switch} from "antd";
import {
  useParams,
  useNavigate
} from "react-router-dom";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputNumber } from "../../common/component";

import { MotorBikeManageService, MotorBikeMaintenanceManageService, RepairPostService } from '../motorBike/motorBikeService';
import { CurrentUserService } from "../user/currentUserService";

const today = moment();

const MotorbikeDetail = (props) => {
  const { extId } = useParams();
  const [itemState, setItemState] = useState({
    extId: extId,
  });
  const [compState, setCompState] = useState(null);
  const [filterState, setFilterState] = useState({
    extId: extId,
    fromDate: today.format('YYYY-MM-DD')
  });

  const [distanceState, setDistanceState] = useState({
    extId: extId,
    fromDate: null, //today.format('YYYY-MM-DD'),
    toDate: null, //today.format('YYYY-MM-DD'),
  });
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });

  let navigate = useNavigate();

  useEffect(() => {
    init();
    getList();
  }, []);

  useEffect(() => {
    getDetailInMonth();
  }, [filterState]);

  useEffect(() => {
    getDistance();
  }, [distanceState])

  const init = () => {
    Popup.spin.show();
    MotorBikeManageService.detail({ ...itemState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setCompState(prevState => ({
          ...prevState,
          item: res.item
        }));
      }
    })
  }

  const getList = () => {
    MotorBikeMaintenanceManageService.getRepairList({ extId: itemState.extId }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState(prevState => ({
          ...prevState,
          itemList: res.itemList,
          totalPayment: res.totalPayment,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        }));
      }
    })
  }

  const getDetailInMonth = () => {
    Popup.spin.show();
    MotorBikeManageService.detailInMonth({ ...filterState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          totalIncomeValueInMonth: res.item.totalIncomeValueInMonth,
          totalMaintenanceValueInMonth: res.item.totalMaintenanceValueInMonth,
          coutingHireInMonth: res.item.coutingHireInMonth
        })
      }
    })
  }

  const getDistance = () => {
    Popup.spin.show();
    MotorBikeManageService.getDistance({ ...distanceState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          totalDistance: res.distanceKmUnit
        })
      }
    })
  }

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = () => {
    props.onOk();
  }

  const onChangePicker = (date) => {
    if(!date) return;
    setFilterState({
      ...filterState,
      fromDate: date.format('YYYY-MM-DD'),
    })
  }

  const onChangeFromDatepicker = (date) => {
    setDistanceState({
      ...distanceState,
      fromDate: date != null ? date.format("YYYY-MM-DD") : null,
    });
  }

  const onChangeToDatepicker = (date) => {
    setDistanceState({
      ...distanceState,
      toDate: date != null ? date.format("YYYY-MM-DD") : null,
    });
  }
  const deleteItem = (item) => {
    Popup.spin.show();
    RepairPostService.delete(item.id).then((res) => {
      Popup.spin.hide();
      getList();
    });
  };

  const onRemoveItem = (item) => {
    Popup.confirm({
      title: Locale.getText("common.delete_confirm"),
      content: item.name,
      onOk: () => deleteItem(item),
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  };

  if(!compState) return <></>
  return (
    <React.Fragment>
      <Row>
        <Col>
        <Button onClick={() => navigate(-1)} icon={<i className="icon fal fa-arrow-left"></i>}>
          Quay lại
        </Button>
        </Col>
      </Row>
      <div className="main">
        <Row>
          <Col>
            <h2>Xe {extId}</h2>
          </Col>
        </Row>
        
        <Row>
          {compState.item &&
            <Col md={6} xs={24} sm={24}>
              <div className="box" style={{minHeight:'20em'}}>
                <div className="title-box">
                  <h2>Thông tin tổng quát</h2>
                </div>
                <div>
                  Loại xe: <strong>{compState.item.type}</strong>
                </div>
                <div>
                  Ngày mua: <strong>{Format.date(new Date(compState.item.created))}</strong>
                </div>
                <div>
                  Trị giá: <strong>{Format.number(compState.item.assetCost)}</strong>
                </div>
                <div>
                  Doanh thu: <strong>{Format.number(compState.item.totalIncomeValue)}</strong>
                </div>
                <div>
                  Bảo trì: <strong>{Format.number(compState.item.totalMaintenanceValue)}</strong>
                </div>
                <div>
                  Số lần thuê: <strong>{Format.number(compState.item.coutingHire)}</strong>
                </div>
              </div>
            </Col>
          }
          <Col md={6} xs={24} sm={24}>
            <div className="box" style={{minHeight:'20em'}}>
              <div className="title-box">
                <h2>Doanh thu tháng</h2>
                <label>
                <DatePicker
                  defaultValue={moment()}
                  format={'MM/YYYY'}
                  onChange={onChangePicker}
                  allowClear={false}
                  picker="month"
                />
                </label>
              </div>
              <div>
                Doanh thu: <strong>{Format.number(itemState.totalIncomeValueInMonth)}</strong>
              </div>
              <div>
                Bảo trì: <strong>{Format.number(itemState.totalMaintenanceValueInMonth)}</strong>
              </div>
              <div>
                Số lần thuê: <strong>{Format.number(itemState.coutingHireInMonth)}</strong>
              </div>
            </div>
          </Col>
          <Col md={6} xs={24} sm={24}>
            <div className="box" style={{minHeight:'20em'}}>
              <div className="title-box">
                <h2>Tổng số km</h2>
              </div>
              <div>
                <div>
                  <DatePicker
                    placeholder="Từ ngày"
                    format={Locale.getFormat().dateFormat}
                    onChange={onChangeFromDatepicker}
                    allowClear={true}
                    style={{marginRight:'1rem'}}
                    />
                  <DatePicker
                    placeholder="Đến ngày"
                    format={Locale.getFormat().dateFormat}
                    onChange={onChangeToDatepicker}
                    allowClear={true}
                    />
                </div>
                <div style={{marginTop:'1rem'}}>
                  Tổng số km: <strong>{Format.number(itemState.totalDistance)}</strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        
        {compState.itemList && <>
          <div className="box">
          <div className="title-box">
            <h3>Tổng chi phí: <strong className="income">{Format.number(compState.totalPayment)}</strong></h3>
          </div>
          <div className="table has-left-col has-menu">
            <ol>
              <li>#</li>
              <li>Số xe</li>
              <li>Ngày sửa</li>
              <li>Mô tả</li>
              <li>Thay dầu</li>
              <li>Số tiền</li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
            </ol>
            {compState.itemList.map((item, index) => (
              <ul key={item.id}>
                <li className="id-col collapsing">{index + 1}</li>
                <li>
                  <a onClick={() => onEditItem(item)}>{item.extId}</a>
                </li>
                <li>{Format.date(new Date(item.dateCreated))}</li>
                <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}>
                </li>
                <li>
                  <Switch defaultChecked={false} checked={item.isOil}></Switch>
                </li>
                <li>
                  {Format.number(item.payment)}
                </li>
                {currentUserState.currentUser && currentUserState.currentUser.canEdit &&
                  <li className="menu-col">
                    <Dropdown
                      placement="bottomRight"
                      trigger={["click"]}
                      overlay={
                        <Menu items={[
                          {
                            key: 'update',
                            label: <a onClick={() => onEditItem(item)}>
                              <span>Cập nhật</span>
                            </a>,
                            icon: <i className="icon fal fa-pen"></i>
                          },
                          {
                            key: 'delete',
                            label: <a onClick={() => onRemoveItem(item)}>
                              <span>Xóa</span>
                            </a>,
                            icon: <i className="icon fal fa-trash-alt"></i>
                          },
                        ]}>
                        </Menu>
                      }
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <i className="far fa-ellipsis-v" />
                      </a>
                    </Dropdown>
                  </li>}
              </ul>
            ))}
          </div>
        </div>
        </>}
        
      </div>
    </React.Fragment>
  )
}

export default MotorbikeDetail;