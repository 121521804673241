import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button, Pagination, Checkbox, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg } from "../../common/component";

import { BillManageService } from './billService';
import { CurrentUserService } from "../user/currentUserService";
import { CommissionManageService } from "../commission/commissionService";

import PostBill from "./postBill";

const ManageReceivable = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList: []
  });
  const partnerListRef = useRef([]);

  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    isPaid: true,
    isDebt: true,
    partnerId: null,
    archived: false
  });

  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

    init();

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const init = () => {
    Popup.spin.show();
    CommissionManageService.init().then(res => {
      Popup.spin.hide()
      if(res.success){
        partnerListRef.current = res.partnerList;
      }
    })
  }

  const getList = () => {
    Popup.spin.show();
    let filterParams = {
      ...filterState,
    };

    BillManageService.getList({ ...filterParams }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
          totalIncomeValue: res.totalIncomeValue,
          totalCommissionValue: res.totalCommissionValue
        });
      }
    })
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const onChangePartner = (value) => {
    setFilterState({
      ...filterState,
      partnerId: value
    });
  }

  const onChangeArchived = (e) => {
    console.log(e.target.checked);
    setFilterState({
      ...filterState,
      archived: e.target.checked
    });
  }

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Công nợ</h1>
      <Row align="middle" justify="end">
        <Col>Số lượng công nợ: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col>
          <Select
            allowClear
            showSearch
            style={{ width: "160px" }}
            placeholder="Tìm đối tác"
            optionFilterProp="children"
            onChange={onChangePartner}
            filterOption={(input, option) =>
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
          >
            {partnerListRef.current.map(item => (
              <Select.Option key={item.partnerId} value={item.partnerId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Checkbox 
              checked={filterState.archived}
              onChange={onChangeArchived}>Lưu trữ HĐ
          </Checkbox>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Tổng giá trị: <strong className="income">{Format.number(compState.totalIncomeValue - compState.totalCommissionValue)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Ngày thuê</li>
            <li>{filterState.isPaid ? "Ngày trả" : "Dự kiến thuê"}</li>
            <li>Đối tác</li>
            <li>Giá thuê</li>
            <li>Hoa hồng</li>
            {filterState.isPaid &&
              <li>Thành tiền</li>}
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <div>
                  <strong>
                    <a onClick={() => onEditItem(item)}>{item.name}</a>
                  </strong>
                </div>
                <em><a onClick={() => onEditItem(item)}>{item.extId}</a></em>
              </li>
              <li>
                <div>{Format.date(new Date(item.dateCreated))}</div>
                <div>{Format.time(new Date(item.dateCreated))}</div>
              </li>
              <li>
              {filterState.isPaid ? 
                <>
                  <div>{Format.date(new Date(item.dateReturn))}</div>
                  <div>{Format.time(new Date(item.dateReturn))}</div>
                </>
                : item.expected + ' ngày'}
              </li>
              <li>
              <strong>{item.partner}</strong>
              </li>
              <li>
                <strong>{Format.number(item.price)}</strong>
              </li>
              <li>
                <strong>{Format.number(item.commission)}</strong>
              </li>
              {filterState.isPaid && <>
                <li>
                  <strong>{Format.number(item.payment)}</strong>
                </li>
              </>}
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li style={{float:'right'}}>
                <Button type="primary" onClick={() => onEditItem(item)}>
                  Cập nhật
                </Button>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
            />
          }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostBill id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageReceivable;