
import * as Service from "../../common/serviceHelper";

export const OrderManageService = {
  init: async (param) => await Service.get("api/order/manage/init", param),
  getList: async (param) => await Service.get("api/order/manage/getlist", param)
}

export const PostService = {
  init: async (param) => await Service.get("api/order/post/init", param),
  submit: async (param) => await Service.post("api/order/post/submit", param),
  get: async (param) => await Service.get("api/order/post/createPdf", param)
}